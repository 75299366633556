<script setup lang="ts">
import {Head} from "@inertiajs/inertia-vue3";
import {nextTick, onMounted, ref} from "vue";
import jquery from "jquery";
import HeaderNavBarHome from "@/Components/Home/HeaderNavBarHome.vue";
import Hero from "@/Components/Home/Hero.vue";
import OurAdvantage from "@/Components/Home/OurAdvantage.vue";
import HowItWorks from "@/Components/Home/HowItWorks.vue";
import WhyUs from "@/Components/Home/WhyUs.vue";
import Pricing from "@/Components/Home/Pricing.vue";
import FAQ from "@/Components/Home/FAQ.vue";
import Testimonial from "@/Components/Home/Testimonial.vue";
import CallToActionTwo from "@/Components/Home/CallToActionTwo.vue";
import Footer from "@/Components/Home/Footer.vue";

window.jQuery = window.$ = jquery;

// loading
const loading = ref(true)
onMounted(() => {
    window.addEventListener('load', () => {
        // run after everything is in-place
        nextTick(() => {
            loading.value = false;
        })

    });
})


</script>

<template>
    <Head>
        <title>UltimateEmailVerifier</title>
        <meta name="description" content="SMTP Email Verifier service with grey listing and ip rotation.">

        <link rel="stylesheet" href="/home/css/bootstrap.css">
        <link rel="stylesheet" href="/home/fonts/icon-font/css/style.css">
        <link rel="stylesheet" href="/home/fonts/typography-font/typo.css">
        <link rel="stylesheet" href="/home/fonts/fontawesome-5/css/all.css">

        <link rel="stylesheet" href="/home/plugins/aos/aos.min.css">
        <link rel="stylesheet" href="/home/plugins/fancybox/jquery.fancybox.min.css">
        <link rel="stylesheet" href="/home/plugins/nice-select/nice-select.min.css">
        <link rel="stylesheet" href="/home/plugins/slick/slick.min.css">

        <link rel="stylesheet" href="/home/plugins/theme-mode-switcher/switcher-panel.css">
        <link rel="stylesheet" href="/home/css/main.css">
    </Head>

    <!-- todo : 1. Actual Pricing, 2. Call To Action Process    -->
    <div class="site-wrapper overflow-hidden ">
        <div v-if="loading" id="loading">
            <img src="/home/image/preloader.gif" alt="">
        </div>
        <div :class="loading? 'loading-hidden' : ''">
            <HeaderNavBarHome/>
            <Hero/>
            <OurAdvantage/>
            <HowItWorks/>
            <WhyUs/>
            <Pricing/>
            <FAQ/>
            <Testimonial/>
            <CallToActionTwo/>

            <!-- Footer Area -->
            <Footer/>
            <!--/ .Footer Area -->
        </div>
    </div>

    <!-- Plugin's Scripts -->
    <!--    <component is="script" src="/home/plugins/jquery/jquery.min.js"></component> -->
    <component is="script" src="/home/plugins/jquery/jquery-migrate.min.js"></component>
    <component is="script" src="/home/js/bootstrap.bundle.js"></component>
    <component is="script" src="/home/plugins/fancybox/jquery.fancybox.min.js"></component>
    <component is="script" src="/home/plugins/nice-select/jquery.nice-select.min.js"></component>
    <component is="script" src="/home/plugins/aos/aos.min.js"></component>
    <component is="script" src="/home/plugins/counter-up/jquery.counterup.min.js"></component>
    <component is="script" src="/home/plugins/counter-up/waypoints.min.js"></component>
    <component is="script" src="/home/plugins/slick/slick.min.js"></component>
    <component is="script" src="/home/plugins/skill-bar/skill.bars.jquery.js"></component>
    <component is="script" src="/home/plugins/isotope/isotope.pkgd.min.js"></component>
    <!--<script src="./plugins/theme-mode-switcher/gr-theme-mode-switcher.js"></script>-->
    <!-- Activation Script -->
    <component is="script" src="/home/js/menu.js"></component>
    <component is="script" src="/home/js/custom.js"></component>


</template>

<style scoped>
.loading-hidden {
    display: none;;
}

div#loading {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}

div#loading img {
    z-index: 9999;
    width: 25%;
}

img, svg {
    vertical-align: middle;
}

@media (min-width: 576px) {
    div#loading img {
        width: 17%;
    }
}

@media (min-width: 768px) {
    div#loading img {
        width: 15%;
    }
}

@media (min-width: 992px) {
    div#loading img {
        width: 7%;
    }
}

</style>
