<script setup lang="ts">

</script>

<template>
    <div class="promo-area promo-area--l7 bg-default">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-12">
                    <div class="pormo--l4-content pormo--l7 dark-mode-texts">
                        <div class="section-title section-title--l7 text-center">
                            <h2 class="section-title__heading"
                                data-aos="fade-up"
                                data-aos-duration="500"
                                data-aos-once="true">Optimize your outreach with <br class="d-none d-lg-block">
                                Powerful Email Verification System</h2>
                            <p class="section-title__description"
                               data-aos="fade-up"
                               data-aos-duration="500"
                               data-aos-delay="300"
                               data-aos-once="true">
                                Developed and maintained by industry-leading SMTP experts.<br />
                                Don’t miss out on boosting your ROI. Sign up today and benefit from our generous free tier!
                            </p>
                            <a class="btn btn--lg-2 btn-readical-red text-white rounded-50 shadow--readical-red-4 mt-2"
                               href="/register"
                               data-aos="fade-up"
                               data-aos-duration="500"
                               data-aos-delay="500"
                               data-aos-once="true">Get Started Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
