<script setup lang="ts">

</script>

<template>
    <div class="content-section content-section--l3-2 bg-default">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-title section-title--l3 text-center">
                        <h6 class="section-title__sub-heading"
                            data-aos="fade-up"
                            data-aos-duration="500"
                            data-aos-once="true">How it Works</h6>
                        <h2 class="section-title__heading mb-4"
                            data-aos="fade-up"
                            data-aos-duration="500"
                            data-aos-delay="300"
                            data-aos-once="true">Simple Automatic Process</h2>
                    </div>
                </div>
            </div>
            <div class="widgets widgets-content--l3"
                 data-aos="fade-up"
                 data-aos-duration="500"
                 data-aos-delay="500"
                 data-aos-once="true">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6 col-xs-6 single-widget">
                        <div class="widget widget--content-l3">
                            <div class="widget--content-l3__circle">
                                <h2 class="widget--content-l3__circle-content bg-electric-violet-2 shadow--electric-violet-2 dark-mode-texts">
                                    1</h2>
                            </div>
                            <h4 class="widget--content-l3__title">Add Data</h4>
                            <p class="widget--content-l3__text">Data Can be added via files, API. or third-party
                                integrations. Data format is preserved.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-xs-6 single-widget">
                        <div class="widget widget--content-l3">
                            <div class="widget--content-l3__circle">
                                <h2 class="widget--content-l3__circle-content bg-flamingo shadow--flamingo dark-mode-texts">
                                    2</h2>
                            </div>
                            <h4 class="widget--content-l3__title">Start Verifying</h4>
                            <p class="widget--content-l3__text">Once the daa is imported, and counted, you can place the
                                order for verification</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-xs-6 single-widget">
                        <div class="widget widget--content-l3">
                            <div class="widget--content-l3__circle">
                                <h2 class="widget--content-l3__circle-content bg-egg-blue shadow--egg-blue dark-mode-texts">
                                    3</h2>
                            </div>
                            <h4 class="widget--content-l3__title">Download Results</h4>
                            <p class="widget--content-l3__text">Download all data or only those that you need. Results
                                can be downloaded as csv..</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
