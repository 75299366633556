<script setup lang="ts">

</script>

<template>
    <div class="testimonial-section testimonial-section--l4 bg-default-3">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-8 col-xl-9 col-xxl-7">
                    <div class="section-title section-title--l4">
                        <h6 class="section-title__sub-heading text-torch-red">Testimonial</h6>
                        <h2 class="section-title__heading mb-4">Don’t Just Take Our Words For It</h2>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center testimonial-slider--l4">
                <div class="col-lg-3 col-md-12">
                    <div class="card card-testimonial--l4">
                        <div class="card-body ">
                            <div class="card-body__top d-flex align-items-center justify-content-between">
                                <div class="d-flex flex-wrap align-items-center">
                                    <div class="card-body__user mr-3">
                                        <h3 class="card-title">Ethan Caldwell</h3>
                                    </div>
                                </div>
                                <div class="card-icon">
                                    <i class="fa fa-quote-left"></i>
                                </div>
                            </div>
                            <p class="card-description">Over the past year, we've tested several email verification
                                services, but this one stands out. The speed and accuracy have been impressive so far.
                                We've even compared...<!-- the results with our own mailing, and the difference is clear.--></p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-12">
                    <div class="card card-testimonial--l4">
                        <div class="card-body ">
                            <div class="card-body__top d-flex align-items-center justify-content-between">
                                <div class="d-flex flex-wrap align-items-center">

                                    <div class="card-body__user mr-3">
                                        <h3 class="card-title">Olivia Marshall</h3>
                                    </div>
                                </div>
                                <div class="card-icon">
                                    <i class="fa fa-quote-left"></i>
                                </div>
                            </div>
                            <p class="card-description">I started with the free tier and within a few months I was able
                                to scale my operations up.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-12">
                    <div class="card card-testimonial--l4">
                        <div class="card-body ">
                            <div class="card-body__top d-flex align-items-center justify-content-between">
                                <div class="d-flex flex-wrap align-items-center">
                                    <div class="card-body__user mr-3">
                                        <h3 class="card-title">Noah Bennett</h3>
                                    </div>
                                </div>
                                <div class="card-icon">
                                    <i class="fa fa-quote-left"></i>
                                </div>
                            </div>
                            <p class="card-description">Customer Service was good and took the time to explain what I
                                could do better.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-12">
                    <div class="card card-testimonial--l4">
                        <div class="card-body ">
                            <div class="card-body__top d-flex align-items-center justify-content-between">
                                <div class="d-flex flex-wrap align-items-center">
                                    <div class="card-body__user mr-3">
                                        <h3 class="card-title">Liam Foster</h3>
                                    </div>
                                </div>
                                <div class="card-icon">
                                    <i class="fa fa-quote-left"></i>
                                </div>
                            </div>
                            <p class="card-description">I was a long time user of another verifier and was invited for
                                the beta run. This is amazing. Now customer for lifetime!</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-12">
                    <div class="card card-testimonial--l4">
                        <div class="card-body ">
                            <div class="card-body__top d-flex align-items-center justify-content-between">
                                <div class="d-flex flex-wrap align-items-center">
                                    <div class="card-body__user mr-3">
                                        <h3 class="card-title">Ava Cooper</h3>
                                    </div>
                                </div>
                                <div class="card-icon">
                                    <i class="fa fa-quote-left"></i>
                                </div>
                            </div>
                            <p class="card-description">Affordable service and accurate results.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<style scoped>

</style>
