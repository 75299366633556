<script setup lang="ts">

</script>

<template>
    <div class="about-us-section about-us-section--l7 bg-default-3">
        <div class="container">
            <div class="row align-items-center justify-content-center">
            <div class="col-xl-6 col-lg-7 col-md-10 order-2 order-lg-1">
                <div class="about-us-left mt-3 mt-lg-5">
                    <div class="section-title section-title--l7 text-center text-lg-end">
                        <h6 class="section-title__sub-heading text-readical-red"
                            data-aos="fade-up"
                            data-aos-duration="500"
                            data-aos-once="true">Why Choose Us</h6>
                        <h2 class="section-title__heading heading-bottom-line--right mb-4"
                            data-aos="fade-up"
                            data-aos-duration="500"
                            data-aos-delay="300"
                            data-aos-once="true"
                        >
                            Unmatched Precision. Superior Technology. Trusted Results.
                        </h2>
                        <p class="section-title__description"
                           data-aos="fade-up"
                           data-aos-duration="500"
                           data-aos-delay="500"
                           data-aos-once="true"
                        >
                            We utilize a full-featured, production-grade SMTP MTA, paired with an
                            <!-- <br class="d-none d-sm-block d-xl-none d-xxl-block">-->
                            intelligent multi-layered validation engine, to deliver results that
                            mirror real-world conditions. Our system retries up to 15 times, ensuring
                            the most accurate and reliable email verification possible.
                        </p>
                    </div>
                    <!--Counter Bar -->
                    <div class="about-us-counter about-us-counter--l4"
                         data-aos="fade-up"
                         data-aos-duration="500"
                         data-aos-delay="700"
                         data-aos-once="true">
                        <div class="counter-widgets">
                            <div class="counter-widgets__single counter-content text-center">
                                <h2 class="counter-content__heading">
                                    <span class="counter">10</span>
                                    <span> M</span>
                                </h2>
                                <p class="counter-content__text">
                                    Emails<br class="d-none d-xs-block">
                                    verified daily</p>
                            </div>
                            <div class="counter-widgets__single counter-content text-center">
                                <h2 class="counter-content__heading"><span class="counter">98</span>%</h2>
                                <p class="counter-content__text">Accuracy<br class="d-none d-xs-block">
                                    Guaranteed</p>
                            </div>
                            <div class="counter-widgets__single counter-content text-center">
                                <h2 class="counter-content__heading"><span class="counter">300</span>+</h2>
                                <p class="counter-content__text">
                                    Verifiable<br class="d-none d-xs-block">
                                    Domains</p>
                            </div>
                        </div>
                    </div>
                    <!--/ .Counter Bar -->
                </div>
            </div>
            <div class="col-xl-6 col-lg-5 col-md-9 order-1 order-lg-2">
                <div class="about-us__image"
                     data-aos="fade-left"
                     data-aos-duration="500"
                     data-aos-once="true">
                    <img class="w-100" style="opacity: 75%" src="/home/image/pexels-fauxels-3183197.jpg" alt=""/>
                    <!-- Video Button -->
                    <!--                        <a class="video-btn btn-white popup-btn sonar-emitter-2 text-white"
                                               href="https://www.youtube.com/watch?v=LWZ7iytIA6k"> <i
                                                class="fa fa-play text-readical-red"></i> </a>-->
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<style scoped>

</style>
