<script setup lang="ts">

</script>

<template>
    <header class="site-header site-header--menu-right dynamic-sticky-bg mt-3 mt-lg-0 dark-mode-texts site-header--absolute site-header--sticky">
        <div class="container">
            <nav class="navbar site-navbar">
                <!-- Brand Logo-->
                <div class="brand-logo">
                    <a href="/">
                        <!-- light version logo (logo must be black)-->
                        <img src="/images/logo_lp.png" alt="" class="light-version-logo">
                        <!-- Dark version logo (logo must be White)-->
                        <img src="/images/logo_lp.png" alt="" class="dark-version-logo">
                    </a>
                </div>

                <div class="menu-block-wrapper  ms-4">
                    <div class="menu-overlay"></div>
                    <nav class="menu-block" id="append-menu-header">
                        <div class="mobile-menu-head">
                            <div class="go-back">
                                <i class="fa fa-angle-left"></i>
                            </div>
                            <div class="current-menu-title"></div>
                            <div class="mobile-menu-close">&times;</div>
                        </div>
                        <ul class="site-menu-main">
                            <li class="nav-item">
                                <a href="#" class="nav-link-item drop-trigger">Home </a>
                            </li>
                            <li class="nav-item">
                                <a href="#features" class="nav-link-item drop-trigger">Features</a>
                            </li>
                            <li class="nav-item">
                                <a href="#pricing" class="nav-link-item drop-trigger">Pricing</a>
                            </li>
                            <li class="nav-item">
                                <a :href="route('FAQs')" class="nav-link-item drop-trigger">FAQ</a>
                            </li>
                            <li class="nav-item">
                                <a href="/support" class="nav-link-item drop-trigger">Support</a>
                            </li>
                            <li class="nav-item">
                                <a href="/login" class="nav-link-item drop-trigger">LogIn </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="header-btn ">
                    <a class="btn btn btn-egg-blue btn--btn-header-7 rounded-50 text-white shadow--egg-blue-2 ms-lg-4 ms-auto d-none d-sm-flex"
                       href="/register">
                        Get Started
                    </a>
                </div>
                <!-- mobile menu trigger -->
                <div class="mobile-menu-trigger">
                    <span></span>
                </div>
                <!--/.Mobile Menu Hamburger Ends-->
            </nav>
        </div>
    </header>
</template>

<style scoped>

</style>
